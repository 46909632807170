import React from 'react'

import { SvgIconProps } from '../ts/types/svgIcon.types'

export const CloseIcon = ({ fillColor, width, height, viewBox, strokeWidth }: SvgIconProps) => (
  <svg
    style={{ fill: fillColor }}
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 14L1 1M14 1L1 14"
      stroke={fillColor || '#6AC52B'}
      strokeWidth={strokeWidth || '2'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
