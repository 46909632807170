import { getGeocode, getLatLng } from 'use-places-autocomplete'
import { getZipCodeUtil } from '../utils/functions'
import { LocationCardProps } from '../ts/types/all.types'

export const LocationCard = ({
  setAddressLine2 = () => {},
  setPlaceId = () => {},
  setInputLocation = () => {},
  place_id,
  main_text = '',
  secondary_text = '',
  description = '',
  clearSuggestions,
  onClick = () => {},
  setPincode = () => {},
  setCity = () => {},
  setDistanceProps = () => {}
}: LocationCardProps) => {
  const clickFun = async () => {
    if (true) {
      getGeocode({ placeId: place_id })
        .then(async (results) => {
          const { lat, lng } = getLatLng(results[0])

          const data = await getZipCodeUtil(lat, lng)
          setDistanceProps({ lat: lat, lng: lng })
          setPincode(data.pincode)
          setCity(data.city)
        })
        .catch((error) => {
          console.log('Error', error)
        })
    }
  }

  return (
    <div
      className="border-box z-11 bg-white visible mt-[0px] h-fit cursor-pointer border border-[1px] border-[#E5E5E5] py-[10px] px-[24px]  opacity-100"
      onClick={async () => {
        setAddressLine2(description)
        setInputLocation(`${main_text}, ${secondary_text}`)
        setPlaceId(place_id)
        clearSuggestions()
        onClick()
        clickFun()
      }}
      key={place_id}
    >
      <div className="font-black text-md font-[500] leading-[19.12px]">{main_text}</div>
      <div className="mt-[5px] font-book text-md font-medium leading-19 text-black-50">
        {secondary_text}
      </div>
    </div>
  )
}
