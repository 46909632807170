import { gql } from '@apollo/client'

const GET_TENANT_DETAILS = gql`
  query MyQuery($tenantId: String!) {
    getTenantDetails(tenantId: $tenantId) {
      kitchenName
      aboutTenant
      currency
      currencySymbol
      isActive
      tagLine
      tenantId
      isSubscriptionActive
      kitchenInactivityEndDate  
      kitchenInactivityStartDate
      isPayOnPickupAllowed
      updatedAt
      timeZone
      updatedBy
      validTill
      website
      email
      foodCertificate
      countryCode
      deliveryType
      averageRating
      fulfillmentType
      ratingCount
      totalReviews
      cuisinesServed
      tenantImages {
        image
        thumbnail
      }
      tenantAddresses {
        mobileNumber
        id
        addressType
        addressLine1
        addressLine2
        pinCode
        state
        latitude
        longitude
        city
        country
      }
      menuSlots {
        name
        startTime
        endTime
      }
      logo
    }
  }
`

export default GET_TENANT_DETAILS
