import { useRouter } from 'next/router'
import classNames from 'classnames'
import React, { useContext } from 'react'
import { AppContext } from './Layout'
import { BackIcon } from '../svgIcons/backIcon'
import { CancelIcon } from '../svgIcons/cancelIcon'
import { DesktopHeader } from './DesktopHeader'
import { LocationHeader } from './LocationHeader'

export const Header = () => {
  const {
    headerDetails,
    showLocationHeader,
    showHeader,
    theme,
    setSelectedTab,
    selectedTab,
    loginDetails,
    setShowCustomisationScreen,
    setOpenCustomisationModal,
    setShowOtpField,
    setIsAddressOpen
  } = useContext(AppContext)
  const router = useRouter()
  const backHandler = () => {
    if (selectedTab[selectedTab.length - 1] === 'Profile' && !loginDetails?.isLogin) {
      setSelectedTab([])
      router.push({ pathname: '/' })
    } else {
      setSelectedTab((prev: string[]): string[] => {
        let previousItems = [...prev]
        previousItems.pop()
        return previousItems
      })
      router.back()
    }
  }

  return (
    <>
      <div className="md:hidden">
        {showHeader ? (
          showLocationHeader ? (
            <div className="fixed top-[0px] z-30">
              <LocationHeader />
            </div>
          ) : (
            <div
              onClick={
                headerDetails?.onClick === 'emptyFunction'
                  ? backHandler
                  : headerDetails?.onClick
                  ? eval(JSON.parse(headerDetails?.onClick))
                  : () => {}
              }
              className={classNames(
                'fixed top-[0px] z-50 flex w-[100%] cursor-pointer items-center py-[14.5px] pl-[24px]'
              )}
              style={{ background: headerDetails?.backgroundColor ?? '#f5f5f6' }}
            >
              <div>
                {headerDetails?.icon === 'back' ? (
                  <BackIcon
                    height="13"
                    width="13"
                    viewBox="0 0 15 16"
                    fillColor={theme.customPrimary}
                  />
                ) : (
                  <CancelIcon
                    height="13"
                    width="13"
                    viewBox="0 0 15 16"
                    fillColor={theme.customPrimary}
                  />
                )}
              </div>
              <div
                style={{
                  color: theme.customPrimary
                }}
                className="ml-[8px] font-black text-md font-[500] leading-[19.12px]"
              >
                {headerDetails?.title || 'Back'}
              </div>
            </div>
          )
        ) : (
          <div></div>
        )}
      </div>
      <div className="hidden md:block">
        {showHeader ? (
          <div className="fixed top-[0px] z-30">
            <DesktopHeader />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </>
  )
}
