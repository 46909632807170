export const TENANT_DETAILS = {
  city: 'Bangalore',
  state: 'KA',
  theme:
    '{"gradient":"#A1E175","customPrimary":"#6AC52B","custom75":"#6ac52bbf","custom50":"#6ac52b1a"}',
  menuSlots: [
    {
      name: 'Breakfast',
      startTime: '07:00:00.626Z',
      endTime: '11:00:00.626Z'
    },
    {
      name: 'Lunch',
      startTime: '10:00:00.626Z',
      endTime: '13:00:00.626Z'
    },
    {
      name: 'Dinner',
      startTime: '21:00:00.626Z',
      endTime: '23:00:00.626Z'
    }
  ]
}
