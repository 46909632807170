import { PropsWithChildren, useMemo } from 'react'
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  from,
  fromPromise
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import Amplify from 'aws-amplify'
import axios from 'axios'

const GRAPHQL_ENDPOINT = `${process.env.NEXT_PUBLIC_API_URL}`
const AWS_REGION = process.env.NEXT_PUBLIC_REGION
Amplify.configure({
  Auth: {
    region: AWS_REGION,
    userPoolId: process.env.NEXT_PUBLIC_USER_POOL_ID,
    identityPoolId: process.env.NEXT_PUBLIC_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.NEXT_PUBLIC_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: false
  }
})

const httpLink = new HttpLink({ uri: `${process.env.NEXT_PUBLIC_API_URL}` })

export const ApolloProviderWrapper = ({ children }: PropsWithChildren) => {
  const authToken =
    typeof window !== 'undefined'
      ? localStorage.getItem('auth_token') === 'undefined'
        ? ''
        : localStorage.getItem('auth_token')
      : ''
  const token = typeof window !== 'undefined' && authToken ? JSON.parse(authToken) : ''

  if (token) {
    const myAppConfig = {
      aws_appsync_graphqlEndpoint: GRAPHQL_ENDPOINT,
      aws_appsync_region: AWS_REGION,
      aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS'
    }
    Amplify.configure(myAppConfig)
  } else {
    const myAppConfig = {
      aws_appsync_graphqlEndpoint: GRAPHQL_ENDPOINT,
      aws_appsync_region: AWS_REGION,
      aws_appsync_authenticationType: 'AWS_IAM'
    }
    Amplify.configure(myAppConfig)
  }

  const client = useMemo(() => {
    const authMiddleware = setContext(async (operation, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`
        }
      }
    })

    const getNewToken = async () => {
      const params = new URLSearchParams()
      params.append('grant_type', 'refresh_token')
      if (process.env.NEXT_PUBLIC_CLIENT_ID) {
        params.append('client_id', process.env.NEXT_PUBLIC_CLIENT_ID)
      }
      params.append('refresh_token', JSON.parse(localStorage.getItem('refresh_token') ?? ''))
      try {
        await axios
          .post(`${process.env.NEXT_PUBLIC_COGNITO_URL}/token`, params)
          .then(({ data }) => {
            localStorage.setItem('auth_token', JSON.stringify(data?.id_token))
            return data?.id_token
          })
          .catch((error) => {
            localStorage.removeItem('loginDetails')
            localStorage.clear()
            localStorage.setItem('auth_token', '')
          })
      } catch (error) {
        localStorage.clear()
        localStorage.setItem('auth_token', '')
        console.log(error)
      }
    }

    const errorLink = onError(({ graphQLErrors, operation, forward }) => {
      if (graphQLErrors) {
        for (let err of graphQLErrors) {
          // @ts-ignore
          switch (err?.errorType) {
            case 'UnauthorizedException':
              return fromPromise(
                getNewToken().catch((error) => {
                  return
                })
              )
                .filter((value) => Boolean(value))
                .flatMap((accessToken) => {
                  const oldHeaders = operation.getContext().headers
                  operation.setContext({
                    headers: {
                      ...oldHeaders,
                      authorization: `Bearer ${accessToken}`
                    }
                  })
                  return forward(operation)
                })
          }
        }
      }
    })

    if (token) {
      return new ApolloClient({
        link: from([authMiddleware, errorLink, httpLink]),
        cache: new InMemoryCache()
      })
    } else {
      return new ApolloClient({
        cache: new InMemoryCache()
      })
    }
  }, [token])

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
