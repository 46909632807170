import React from 'react'
import { SvgIconProps } from '../ts/types/svgIcon.types'

export const DeliveryIcon = ({
  height,
  width,
  fillColor,
  viewBox,
  opacity = false
}: SvgIconProps) => {
  return (
    <svg width="26" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.714 8.71424C17.714 7.7657 16.9483 7 15.9997 7H14.2855V8.14283H15.9997C16.3197 8.14283 16.5711 8.39425 16.5711 8.71424V10.1885L12.9026 13.857H10.857V9.28566H7.42849C5.53139 9.28566 4 10.8171 4 12.7141V14.9998H6.28566C6.28566 16.2569 7.3142 17.2855 8.57132 17.2855C9.82843 17.2855 10.857 16.2569 10.857 14.9998H13.3826L17.714 10.6685V8.71424ZM8.57132 16.1426C7.94276 16.1426 7.42849 15.6284 7.42849 14.9998H9.71415C9.71415 15.6284 9.19987 16.1426 8.57132 16.1426Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path d="M10.857 7H6.28564V8.14283H10.857V7Z" fill={fillColor} />
      <path
        d="M17.7139 12.7141C16.4568 12.7141 15.4282 13.7427 15.4282 14.9998C15.4282 16.2569 16.4568 17.2854 17.7139 17.2854C18.971 17.2854 19.9995 16.2569 19.9995 14.9998C19.9995 13.7427 18.971 12.7141 17.7139 12.7141ZM17.7139 16.1426C17.0853 16.1426 16.5711 15.6283 16.5711 14.9998C16.5711 14.3712 17.0853 13.8569 17.7139 13.8569C18.3424 13.8569 18.8567 14.3712 18.8567 14.9998C18.8567 15.6283 18.3424 16.1426 17.7139 16.1426Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
    </svg>
  )
}
