import { notification } from 'antd'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import React, { useContext, useEffect, useState } from 'react'
import { AllergenCrossIcon } from '../svgIcons/allergenCrossIcon'
import { AppContext } from './Layout'
import { ImageWithBasePath } from './ImageWithBasePath'
import { PRODUCT } from '../ts/interfaces/product.interface'
import { TickIcon } from '../svgIcons/tickIcon'
import { CartItemProps } from '../ts/types/cartItem.types'
import { ItemCustomizationProps, NotificationType } from '../ts/types/all.types'

export const ItemCustomization = ({
  forDesktop = false,
  setModalPrice,
  activatebutton,
  setActivateButton,
  setActivateSave
}: ItemCustomizationProps) => {
  const {
    customisationItem,
    setCartItems,
    setShowCustomisationScreen,
    openCustomisationModal,
    tickHandlerObject,
    setOpenCustomisationModal,
    setTickHandlerObject,
    setHeaderDetails,
    reCustomiseItem,
    cartItems,
    theme,
    setCustomisationItem,
    tenant
  } = useContext(AppContext)
  const [requiredItem, setRequiredItem] = useState<any>([])
  const [api, contextHolder] = notification.useNotification()
  const [instructions, setInstructions] = useState(customisationItem?.notes || '')
  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api.destroy()
    api[type]({
      message,
      placement: 'top'
    })
  }

  useEffect(() => {
    setInstructions(customisationItem?.notes)
  }, [customisationItem?.notes])

  const productVarientHandler = () => {
    let varientsObject: any = {}
    setRequiredItem([])
    customisationItem?.foodDetails?.productVariants?.map(
      (item: { isRequired: boolean; name: string }) => {
        if (item?.isRequired) {
          setRequiredItem((prev: any): any => [...prev, item.name])
        }
        varientsObject[item.name] = []
      }
    )
    setTickHandlerObject(varientsObject)
  }

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  useEffect(() => {
    if (Object.keys(reCustomiseItem).length > 0) {
      setCustomisationItem(reCustomiseItem)
      setTickHandlerObject(
        JSON.parse(
          JSON.stringify(
            cartItems.find(
              (item: CartItemProps) =>
                item?.customisationString === reCustomiseItem?.customisationString
            )?.customisation[0]
          )
        )
      )
    } else {
      productVarientHandler()
    }
  }, [customisationItem, reCustomiseItem])
  const [price, setPrice] = useState(0)
  const [cusString, setCusString] = useState('')
  const router = useRouter()
  const redirectionHandler = () => {
    setShowCustomisationScreen(false)
    setOpenCustomisationModal(false)
    if (openCustomisationModal) {
      if (setActivateButton) {
        setActivateButton((prev: { button: boolean; slide: boolean }) => {
          let previousItems = { ...prev }
          previousItems.button = false
          previousItems.slide = true
          return previousItems
        })
      }
    }
  }

  const requiredItemCheckHandler = () => {
    let count = 0
    Object.keys(tickHandlerObject)?.map((item: any) => {
      if (requiredItem?.includes(item) && tickHandlerObject?.[item]?.length > 0) {
        count += 1
      }
    })
    if (count === requiredItem?.length) {
      if (setActivateSave) {
        setActivateSave(true)
      }
      return true
    } else {
      if (setActivateSave) {
        setActivateSave(false)
      }
      return false
    }
  }

  const customisationHandler = () => {
    if (requiredItem?.length === 0 || (cusString?.length > 0 && requiredItemCheckHandler())) {
      let flag = 0
      let customisationString = ''
      Object.keys(tickHandlerObject)?.map((item: any) => {
        tickHandlerObject[item]?.map((flavour: { price: number; title: string }) => {
          if (item === 'Allergens') {
            customisationString += `No ${flavour?.title},`
          } else {
            customisationString += `${flavour?.title},`
          }
        })
      })
      setCusString(customisationString)

      if (Object?.keys?.(reCustomiseItem)?.length > 0) {
        setCartItems((prev: CartItemProps[]) => {
          let previousItems = JSON?.parse?.(JSON?.stringify([...prev]))
          previousItems.map(
            (item: {
              customisation: any
              foodDetails: PRODUCT
              extraPrice: number
              customisationString: string
              notes: string
            }) => {
              if (item?.customisationString === reCustomiseItem?.customisationString) {
                flag = 1
                if (item?.extraPrice !== undefined) {
                  item.extraPrice = price
                }
                if (item?.customisation) {
                  item.customisation = JSON.parse(JSON.stringify([tickHandlerObject]))
                }
                if (item?.customisationString !== undefined) {
                  item.customisationString = cusString
                }
                if (item?.notes !== instructions) {
                  item.notes = instructions
                  reCustomiseItem.notes = instructions
                }
              }
            }
          )
          return previousItems
        })
      }
      if (flag === 1) {
        redirectionHandler()
      }
      if (flag === 0) {
        setCartItems((prev: CartItemProps[]) => {
          let previousItems = JSON?.parse?.(JSON?.stringify([...prev]))
          let check = 0
          previousItems.map((product: CartItemProps) => {
            if (product?.foodDetails?.title === customisationItem?.foodDetails?.title) {
              if (product?.customisationString === cusString) {
                check = 1
                if (product?.quantity) {
                  product.quantity = product.quantity + 1
                }
              }
            }
          })
          if (check === 0) {
            previousItems.push({
              foodDetails: customisationItem?.foodDetails,
              quantity: 1,
              customisation: [tickHandlerObject],
              extraPrice: price,
              customisationString: cusString,
              notes: instructions
            })
          }
          return previousItems
        })
        redirectionHandler()
      }
    } else {
      openNotificationWithIcon('error', 'Please select the mandatory fields')
    }
  }

  useEffect(() => {
    if (activatebutton?.button) {
      customisationHandler()
    }
  }, [activatebutton])

  useEffect(() => {
    let price = 0
    let customisationString = ''
    Object.keys(tickHandlerObject)?.map((item: any) => {
      tickHandlerObject[item]?.map((flavour: { price: number; title: string }) => {
        if (item === 'Allergens') {
          customisationString += `No ${flavour?.title},`
        } else {
          customisationString += `${flavour?.title},`
        }
        price += flavour.price ? Number(flavour.price) : 0
      })
    })
    setCusString(customisationString)
    if (setActivateSave) {
      requiredItemCheckHandler()
    }
    setPrice(price)
    if (setModalPrice) {
      setModalPrice(price)
    }
  }, [tickHandlerObject])

  useEffect(() => {
    setHeaderDetails({
      title: 'Cancel',
      onClick: JSON.stringify(`() => {
        setShowCustomisationScreen(false)
        setOpenCustomisationModal(false)
      }`)
    })
  }, [])

  const getIcon = (type: string) => {
    if (type === 'Non-Veg') return 'nonVegIcon'
    if (type === 'Veg') return 'vegIcon'
    if (type === 'Vegan') return 'veganIcon'
    if (type === 'Halal') return 'halalIcon'
    return ''
  }

  return (
    <div className="bg-[white]">
      {contextHolder}
      <div className="relative h-screen animate-slideUp bg-[white]">
        <div className="scroll bg-[white] px-[24px]">
          {!forDesktop && (
            <div className="md:hidden">
              <div className="mt-[16px] font-black text-2xl font-[500] leading-[27.32px]">
                Customise dish
              </div>
              <div className="flex items-center py-[16px]">
                <div className="-mt-[3px]">
                  <ImageWithBasePath
                    src={getIcon(customisationItem?.foodDetails?.dishType)}
                    height={15}
                    width={15}
                  />
                </div>
                <div className="ml-[8px] font-black text-lg font-[500] leading-[21.86px]">
                  {customisationItem?.foodDetails?.title}
                </div>
              </div>
            </div>
          )}
          {customisationItem?.foodDetails?.productVariants
            ?.sort((a: any, b: any) => {
              if (a?.name > b?.name) return 1
              if (a?.name < b?.name) return -1
              if (a?.name === b?.name) return 0
            })
            .map((item: any) => {
              if (item?.variantOptions?.length <= 0) return null
              return (
                <div>
                  <div className="flex justify-between">
                    <div
                      className={classNames(
                        'text-md leading-[19.12px]',
                        forDesktop ? 'font-book font-medium' : 'font-black font-[500]'
                      )}
                    >
                      {`${item?.name}`}
                      <span className="pl-[2px] text-[#DB3D1A]">{item?.isRequired && '*'}</span>
                    </div>
                    <div
                      className={classNames(
                        'text-sm leading-[16.39px]',
                        forDesktop ? 'font-book font-medium' : 'font-black font-[500]'
                      )}
                    >
                      {item?.allowedNumberOfOptions === 1
                        ? 'Choose only 1'
                        : `Choose up to ${item?.allowedNumberOfOptions}`}
                    </div>
                  </div>
                  <div
                    className={classNames(
                      'mt-[8px] mb-[36px] flex w-[100%] flex-col border-[1px] border-[#E5E5E5]  px-[16px] py-[12px]',
                      forDesktop && 'rounded-[8px]'
                    )}
                  >
                    {item?.variantOptions?.map((varient: any, index: number) => (
                      <div
                        className={classNames('flex w-[100%] items-end', index > 0 && 'mt-[8px]')}
                        onClick={() =>
                          setTickHandlerObject((prev: any) => {
                            let flag = 0
                            prev[item?.name].map((flavour: { title: string }, index: number) => {
                              if (flavour.title === varient?.title) {
                                flag = 1
                                prev[item?.name].splice(index, 1)
                              }
                            })
                            if (flag == 0) {
                              if (prev[item?.name]?.length !== item?.allowedNumberOfOptions) {
                                prev[item?.name].push(varient)
                              } else {
                                let name = item?.name?.split(' ')
                                openNotificationWithIcon(
                                  'warning',
                                  `You have already selected ${item?.allowedNumberOfOptions} ${
                                    item?.allowedNumberOfOptions > 1 ? 'options' : 'option'
                                  } in ${name[name.length - 1].toLocaleLowerCase()}`
                                )
                              }
                            }
                            return { ...prev }
                          })
                        }
                      >
                        <div className="flex w-[30%] font-book text-sm font-medium leading-[16.39px] text-[#000000]">
                          {varient?.title}
                        </div>
                        <div className="ml-[21px] flex items-end">
                          {tickHandlerObject?.[item?.name]?.find(
                            (flavour: { title: string }) => flavour?.title === varient?.title
                          ) ? (
                            item?.name === 'Allergens' ? (
                              <AllergenCrossIcon
                                height="20"
                                width="20"
                                viewBox="0 0 20 20"
                                fillColor="#DB3D1A"
                              />
                            ) : (
                              <TickIcon
                                height="20"
                                width="20"
                                viewBox="0 0 20 20"
                                fillColor="#BAD83A"
                              />
                            )
                          ) : (
                            <div
                              className={classNames(
                                'h-[20px] w-[20px] border-[1px] border-[#E5E5E5] bg-[#F5F5F6]'
                              )}
                            ></div>
                          )}
                          <div className="pl-[21px] font-black text-sm font-[500] leading-[16.39px]">{`+ ${tenant?.currencySymbol}${varient?.price}`}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )
            })}
          <div className={classNames('w-[100%]', forDesktop ? 'pb-[70px]' : 'pb-[55px]')}>
            <div
              className={classNames(
                'text-md leading-[19.12px]',
                forDesktop ? 'font-book font-medium' : 'font-black font-[500]'
              )}
            >
              Instructions to the chef (max 1000 characters)
            </div>
            <textarea
              placeholder="Write any specific instructions that need to be conveyed to the chef"
              className="mt-[8px] w-[100%] border-[1px] border-[#E5E5E5] py-[12px] px-[16px] font-book text-sm font-medium leading-[21.6px]"
              style={{
                outline: 'none'
              }}
              maxLength={1000}
              id="textArea"
              onChange={(e) => setInstructions(e.target.value)}
              value={instructions ?? customisationItem?.notes}
            />
          </div>
        </div>
      </div>
      <div className="fixed bottom-[0px] left-[0px] z-20 w-[100%] bg-[white] px-[24px] pb-[16px] md:hidden">
        {!forDesktop && (
          <div
            className="flex w-full cursor-pointer select-none items-center justify-center py-[14.5px] text-center font-black font-[500] leading-[16.39px]"
            onClick={customisationHandler}
            style={{
              background:
                (cusString.length > 0 || requiredItem?.length === 0) && requiredItemCheckHandler()
                  ? `linear-gradient(135deg, ${theme.customPrimary} 0%, ${theme.gradient} 100%)`
                  : '#E5E5E5'
            }}
          >
            {`Save and add to cart | ${tenant?.currencySymbol} ${
              customisationItem?.foodDetails?.price + price
            }`}
          </div>
        )}
      </div>
    </div>
  )
}
