import React from 'react'
import { SvgIconProps } from '../ts/types/svgIcon.types'

export const DesktopNotificationIcon = ({ fillColor, width, height, viewBox }: SvgIconProps) => {
  return (
    <svg
      fill="none"
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.30064 2.97739C9.4376 2.63887 9.67253 2.34897 9.97532 2.14484C10.2781 1.94072 10.635 1.83167 11.0001 1.83167C11.3653 1.83167 11.7222 1.94072 12.025 2.14484C12.3277 2.34897 12.5627 2.63887 12.6996 2.97739C14.0552 3.35018 15.2508 4.15765 16.103 5.27578C16.9552 6.39391 17.4167 7.76087 17.4168 9.16672V13.4723L19.0961 15.9913C19.1882 16.1294 19.2411 16.2898 19.2492 16.4556C19.2572 16.6213 19.2201 16.7862 19.1419 16.9325C19.0636 17.0788 18.947 17.2012 18.8046 17.2864C18.6623 17.3717 18.4994 17.4167 18.3335 17.4167H14.1764C14.066 18.1804 13.6842 18.8787 13.1009 19.3838C12.5175 19.8889 11.7718 20.1669 11.0001 20.1669C10.2285 20.1669 9.48274 19.8889 8.89941 19.3838C8.31608 18.8787 7.93426 18.1804 7.82389 17.4167H3.6668C3.50085 17.4167 3.338 17.3717 3.19563 17.2864C3.05326 17.2012 2.93671 17.0788 2.85842 16.9325C2.78013 16.7862 2.74303 16.6213 2.75108 16.4556C2.75913 16.2898 2.81203 16.1294 2.90414 15.9913L4.58347 13.4723V9.16672C4.58347 6.21139 6.5818 3.72172 9.30064 2.97739ZM9.70397 17.4167C9.79864 17.685 9.97419 17.9173 10.2064 18.0816C10.4386 18.2459 10.7161 18.3341 11.0006 18.3341C11.2851 18.3341 11.5625 18.2459 11.7948 18.0816C12.027 17.9173 12.2025 17.685 12.2972 17.4167H9.70305H9.70397ZM11.0001 4.58339C9.78456 4.58339 8.61877 5.06627 7.75923 5.92581C6.89969 6.78535 6.4168 7.95114 6.4168 9.16672V13.7501C6.41684 13.9311 6.36326 14.1081 6.2628 14.2588L5.38005 15.5834H16.6193L15.7366 14.2588C15.6364 14.108 15.5832 13.931 15.5835 13.7501V9.16672C15.5835 7.95114 15.1006 6.78535 14.241 5.92581C13.3815 5.06627 12.2157 4.58339 11.0001 4.58339Z"
        fill={fillColor}
      />
    </svg>
  )
}
