import { useContext, useEffect, useState } from 'react'
import { AppContext } from './Layout'
import { ChatIcon } from '../svgIcons/chatIcon'
import { ChatMobile } from './ChatMobile'
import { LocationIcon } from '../svgIcons/locationIcon'
import { SetLocation } from './SetLocation'

export const LocationHeader = () => {
  const {
    location,
    setLocation,
    theme,
    locationShow,
    isChatOpened,
    setIsChatOpened,
    setLocationHeaderClicked
  } = useContext(AppContext)
  const [open, setOpen] = useState<boolean>(false)
  const [showLocationPicker, setShowLocationPicker] = useState(false)

  useEffect(() => {
    if (locationShow) {
      if (location?.secondary_text === '') setOpen(true)
    }
  }, [])

  return (
    <div className="hideOnDesktop md:hidden">
      <div className="fixed z-20 flex w-[100%] items-center justify-between bg-[#F5F5F6] px-[24px] py-[8px] shadow-headerShadow">
        <div
          onClick={() => {
            setIsChatOpened(true)
            setOpen(true)
            setLocationHeaderClicked(true)
          }}
        >
          <div className="mb-[2px] flex">
            <div className="mt-[1px]">
              <LocationIcon
                width="16"
                height="16"
                fillColor={theme?.customPrimary}
                viewBox="0 0 16 16"
              />
            </div>
            <div className="ml-[7px] font-black text-lg font-[500] not-italic leading-22 w-[153px] overflow-hidden text-ellipsis whitespace-nowrap">
              {location?.main_text || 'Select Location'}
            </div>
          </div>
          <div className="w-[173px] overflow-hidden text-ellipsis whitespace-nowrap font-book text-sm font-medium not-italic leading-22 tracking-[-0.408px] ">
            {location?.secondary_text || null}
          </div>
        </div>
        <div
          className="flex items-end"
          onClick={() => {
            setIsChatOpened(false)
          }}
        >
          <div className="mr-[8px] font-book text-sm leading-[16.39px]">Contact Chef</div>
          <div className="-mt-[2px]">
            <ChatIcon
              width="18"
              height="18"
              fillColor={theme?.customPrimary}
              gradientColor={theme?.gradient}
              viewBox="0 0 18 18"
            />
          </div>
        </div>
      </div>
      {locationShow && (
        <SetLocation
          {...{
            open,
            setOpen,
            setLocation,
            showLocationPicker,
            setShowLocationPicker
          }}
        />
      )}
      {open && !locationShow && (
        <SetLocation
          {...{
            open,
            setOpen,
            setLocation,
            showLocationPicker: true,
            setShowLocationPicker
          }}
        />
      )}
      <ChatMobile isChatOpened={isChatOpened} setIsChatOpened={setIsChatOpened} />
    </div>
  )
}
