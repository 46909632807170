import { gql } from '@apollo/client'

const GET_NOTIFICATIONS = gql`
  query MyQuery {
    getUserNotifications {
      tenantId
      userId
      title
      description
      createdAt
      id
      notificationType
      notificationFor
      order {
        orderId
        finalAmount
        orderDate
        orderStatus
        review {
          id
        }
      }
      user {
        firstName
        lastName
      }
    }
  }
`
export default GET_NOTIFICATIONS
