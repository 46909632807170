import { useContext } from 'react'
import classNames from 'classnames'
import { AppContext } from './Layout'
import { PrimaryButtonProps } from '../ts/types/all.types'

export const PrimaryButton = ({
  title,
  className,
  onClick = () => {},
  style
}: PrimaryButtonProps) => {
  const { theme } = useContext(AppContext)
  return (
    <div
      className={classNames(
        'flex w-full cursor-pointer select-none items-center justify-center text-center font-black font-[500] leading-[16.39px]',
        className
      )}
      style={{
        background: `linear-gradient(135deg, ${theme?.customPrimary} 0%, ${theme?.gradient} 100%)`,
        ...style
      }}
      onClick={onClick}
    >
      {title}
    </div>
  )
}
