import { gql } from '@apollo/client'

const NOTIFICATION_LIST = gql`
  subscription MySubscription($tenantId: ID!, $userId: ID!) {
    notificationByTenantIdAndUserId(tenantId: $tenantId, userId: $userId) {
      id
      createdAt
      description
      tenantId
      notificationFor
      title
      userId
      order {
        orderId
        finalAmount
        orderDate
        orderStatus
        review {
          id
        }
      }
    }
  }
`
export default NOTIFICATION_LIST
