import React from 'react'
import { SvgIconProps } from '../ts/types/svgIcon.types'

export const ChatIcon = ({
  fillColor,
  width,
  height,
  viewBox,
  fillInnerColor,
  gradientColor
}: SvgIconProps) => (
  <svg
    style={{ fill: fillColor }}
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9" cy="9" r="9" fill={fillColor} />
    <path
      d="M13.8933 7.89554C13.6625 6.8096 13.0504 5.82121 12.1696 5.1125C11.2689 4.39181 10.1496 3.99943 8.99599 4C7.63618 4 6.36609 4.52388 5.41654 5.47522C4.49891 6.39576 3.99601 7.61116 4.00002 8.89665C4.00006 9.83913 4.27675 10.7609 4.79578 11.5475L4.89288 11.6826L4.35717 14L6.91966 13.3625C6.91966 13.3625 6.971 13.3797 7.00895 13.3942C7.04689 13.4087 7.37345 13.5339 7.71988 13.6308C8.00783 13.7112 8.60693 13.8317 9.07635 13.8317C10.4078 13.8317 11.6511 13.3161 12.577 12.3795C13.4946 11.4502 14 10.2147 14 8.89933C14.0001 8.5619 13.9643 8.22541 13.8933 7.89554Z"
      fill={fillInnerColor || 'white'}
    />
    <defs>
      <linearGradient
        id="paint0_linear_11_210"
        x1="0"
        y1="0"
        x2="18"
        y2="18"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={fillColor} />
        <stop offset="1" stopColor={gradientColor} />
      </linearGradient>
    </defs>
  </svg>
)
