import React from 'react'
import { SvgIconProps } from '../ts/types/svgIcon.types'

export const LogoutIcon = ({ fillColor, width, height, viewBox }: SvgIconProps) => (
  <svg
    style={{ fill: 'white' }}
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 13V11C19 10.4696 18.7893 9.96086 18.4142 9.58579C18.0391 9.21071 17.5304 9 17 9H10C9.46957 9 8.96086 9.21071 8.58579 9.58579C8.21071 9.96086 8 10.4696 8 11V23C8 23.5304 8.21071 24.0391 8.58579 24.4142C8.96086 24.7893 9.46957 25 10 25H17C17.5304 25 18.0391 24.7893 18.4142 24.4142C18.7893 24.0391 19 23.5304 19 23V21"
      stroke={fillColor}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 17H26M26 17L23 14M26 17L23 20"
      stroke={fillColor}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
