import { gql } from '@apollo/client'

const GET_PROFILE = gql`
  query MyQuery {
    getProfile {
      previouslyOrdered {
        orderNumber
        orderDetails {
          id
          product {
            id
            title
            description
            isActive
            dishType
            likes
            disLikes
            productImages {
              id
              image
              thumbnail
            }
            productVariants {
              id
              name
              description
              variantType
              isRequired
              allowedNumberOfOptions
              variantOptions {
                id
                variantId
                title
                description
                price
              }
            }
            category {
              id
              name
            }
          }
        }
        amount
        orderNumber
        cancellationFee
        isPaymentRefunded
        cancellationReason
        cancelledAt
        createdAt
        orderId
        notes
        orderType
        discount
        discountType
        finalAmount
        orderDate
        orderStatus
        userId
      }
      email
      profilePicture
      sub
      username
      firstName
      lastName
      mobileNumber
      addresses {
        addressLine1
        addressLine2
        addressType
        city
        userId
        state
        landmark
        addressType
        latitude
        longitude
        mobileNumber
        pinCode
        id
      }
      tenant {
        kitchenName
        aboutTenant
        currency
        isActive
        tagLine
        tenantId
        updatedAt
        updatedBy
        validTill
        website
        cuisinesServed
        tenantImages {
          image
          thumbnail
        }
        foodCertificate
        tenantAddresses {
          mobileNumber
          id
          addressType
          addressLine1
          addressLine2
          pinCode
          state
          latitude
          longitude
          city
          country
        }
        menuSlots {
          name
          startTime
          endTime
        }
        logo
      }
    }
  }
`

export default GET_PROFILE
