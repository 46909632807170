import React from 'react'
import { SvgIconProps } from '../ts/types/svgIcon.types'

export const AllergenCrossIcon = ({ fillColor, width, height, viewBox }: SvgIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="19" height="19" fill={fillColor} />
      <path
        d="M14 14L6 6"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 6L6 14"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="0.5" y="0.5" width="19" height="19" stroke="#E5E5E5" />
    </svg>
  )
}
