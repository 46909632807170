import React from 'react'
import { SvgIconProps } from '../ts/types/svgIcon.types'

export const UserIcon = ({ fillColor, width, height, viewBox }: SvgIconProps) => (
  <svg
    style={{ fill: fillColor }}
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9888 4.5L12.9856 4.58037L12.9856 4.58029L11.9888 4.5ZM5.01118 4.5L4.01429 4.57884L4.0143 4.57892L5.01118 4.5ZM1.01416 15.1975L1.99696 15.3822L1.99696 15.3821L1.01416 15.1975ZM15.9858 15.1975L16.9686 15.0128L16.9686 15.0128L15.9858 15.1975ZM10.9921 4.41963C10.8726 5.90106 9.65284 7 8.5 7V9C10.836 9 12.7942 6.95404 12.9856 4.58037L10.9921 4.41963ZM8.5 7C7.34597 7 6.12518 5.90036 6.00806 4.42108L4.0143 4.57892C4.20246 6.95547 6.16521 9 8.5 9V7ZM6.00807 4.42116C5.90207 3.0809 6.89505 2 8.5 2V0C5.90251 0 3.80312 1.90868 4.01429 4.57884L6.00807 4.42116ZM8.5 2C10.0985 2 11.0975 3.111 10.992 4.41971L12.9856 4.58029C13.1973 1.9515 11.1039 0 8.5 0V2ZM8.4998 9C4.84931 9 0.794837 10.9488 0.0313489 15.0129L1.99696 15.3821C2.50999 12.6512 5.35369 11 8.4998 11V9ZM0.0313582 15.0128C-0.0517615 15.4552 0.0278412 15.9383 0.309078 16.3328C0.604445 16.7471 1.08059 17 1.62507 17V15C1.66316 15 1.72218 15.0092 1.78723 15.0426C1.85146 15.0756 1.90231 15.1223 1.93759 15.1718C2.00575 15.2674 2.00313 15.3493 1.99696 15.3822L0.0313582 15.0128ZM1.62507 17H15.3745V15H1.62507V17ZM15.3745 17C15.9189 17 16.3952 16.7474 16.6907 16.333C16.9722 15.9384 17.0518 15.4552 16.9686 15.0128L15.003 15.3822C14.9969 15.3493 14.9942 15.2673 15.0625 15.1716C15.0978 15.1221 15.1487 15.0754 15.2128 15.0425C15.2778 15.0092 15.3367 15 15.3745 15V17ZM16.9686 15.0128C16.2047 10.9488 12.1503 9 8.4998 9V11C11.6459 11 14.4897 12.6512 15.0031 15.3822L16.9686 15.0128Z"
      fill={fillColor}
    />
  </svg>
)
