import _JSXStyle from 'styled-jsx/style'
import { Popover } from 'antd'
import { useApolloClient } from '@apollo/client'
import { useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { AppContext } from './Layout'
import { LogoutIcon } from '../svgIcons/logoutIcon'
import { UserIcon } from '../svgIcons/userIcon'

export const ProfileDropdown = () => {
  const {
    showProfileDropdown,
    setShowProfileDropdown,
    theme,
    setToken,
    setPhoneNumber,
    setCartItems,
    setMealDate,
    setMealType,
    setLoginDetails,
    setPreviouslyOrderedItems,
    setNotifications,
    setLoggedOut
  } = useContext(AppContext)
  const router = useRouter()
  const [hover, setHover] = useState('')
  const client = useApolloClient()

  const handleOpenChange = (newOpen: boolean) => {
    setShowProfileDropdown(newOpen)
  }

  const handleScroll = () => {
    setShowProfileDropdown(false)
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const content = (
    <div className="h-[96px] w-[161px] shadow-desktopHeaderShadow ">
      <button
        className="ml-[25px] mr-[20px] flex items-center pt-[16px] pb-[17px]"
        onClick={async () => {
          router.push({ pathname: '/profile' })
          setShowProfileDropdown(false)
        }}
        onMouseEnter={() => {
          setHover('profile')
        }}
        onMouseLeave={() => {
          setHover('')
        }}
      >
        <UserIcon width="15" height="15" viewBox="0 0 17 17" fillColor={theme.customPrimary} />
        <div
          className="ml-[14px] font-black text-sm font-medium leading-16 hover:transition-all hover:delay-500 hover:duration-300"
          style={{ color: hover === 'profile' ? theme.customPrimary : '' }}
        >
          Manage Profile
        </div>
      </button>
      <div className="border-b text-[#2F5E0F1A]"></div>
      <button
        className="ml-[18px] flex items-center py-[6px]"
        onClick={async () => {
          setToken('')
          setShowProfileDropdown(false)
          setPhoneNumber('')
          setCartItems([])
          setMealType({
            type: '',
            timeSlot: '',
            endTime: '',
            startTime: '',
            isPickedTimeSlot: false
          })
          setMealDate({ originalDate: '', modifiedDate: '', isoString: '' })
          setLoginDetails(
            (prev: { isLogin?: boolean; userName?: string; phoneNumber?: string }) => ({
              ...prev,
              isLogin: false
            })
          )
          setPreviouslyOrderedItems([])
          setNotifications([])
          setLoggedOut(true)
          await client.clearStore()
          await client.cache.reset()
          localStorage.clear()
          await router.push({ pathname: '/' })
        }}
        onMouseEnter={() => {
          setHover('logout')
        }}
        onMouseLeave={() => {
          setHover('')
        }}
      >
        <LogoutIcon width="34" height="34" viewBox="0 0 34 34" fillColor={theme.customPrimary} />
        <div
          style={{ color: hover === 'logout' ? theme.customPrimary : '' }}
          className="ml-[4px] font-black text-sm font-medium leading-16 hover:transition-all hover:delay-500 hover:duration-300"
        >
          Logout
        </div>
      </button>
    </div>
  )

  return (
    <div className="absolute right-[134px] top-[45px] ">
      <Popover
        open={showProfileDropdown}
        content={content}
        onOpenChange={(e) => handleOpenChange(e)}
        trigger="click"
        placement="bottom"
      ></Popover>
      <style jsx global>{`
        .ant-popover .ant-popover-inner {
          padding: 0px;
          border-radius: 0px;
        }
      `}</style>
      <style jsx global>{`
        .ant-popover .ant-popover-arrow::before {
          width: 0px;
          height: 0px;
        }
      `}</style>
      <style jsx global>{`
        .ant-popover .ant-popover-arrow::after {
          width: 0px;
          height: 0px;
        }
      `}</style>
    </div>
  )
}
