import React from 'react'
import { SvgIconProps } from '../ts/types/svgIcon.types'

export const HomeIcon = ({
  fillColor,
  width,
  height,
  viewBox,
  newNotification = false
}: SvgIconProps) => (
  <svg
    style={{ fill: fillColor }}
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.614 2.20972C12.4385 2.07316 12.2224 1.99902 12 1.99902C11.7776 1.99902 11.5615 2.07316 11.386 2.20972L3.158 8.60972C2.79716 8.89041 2.50524 9.24987 2.30455 9.66062C2.10385 10.0714 1.99968 10.5226 2 10.9797V19.9997C2 20.5302 2.21071 21.0389 2.58579 21.4139C2.96086 21.789 3.46957 21.9997 4 21.9997H8C8.53043 21.9997 9.03914 21.789 9.41421 21.4139C9.78929 21.0389 10 20.5302 10 19.9997V13.9997H14V19.9997C14 20.5302 14.2107 21.0389 14.5858 21.4139C14.9609 21.789 15.4696 21.9997 16 21.9997H20C20.5304 21.9997 21.0391 21.789 21.4142 21.4139C21.7893 21.0389 22 20.5302 22 19.9997V10.9797C22.0001 10.5224 21.8956 10.0711 21.6946 9.66034C21.4935 9.24957 21.2012 8.89021 20.84 8.60972L12.614 2.20972Z"
      fillOpacity="0.5"
    />
  </svg>
)
