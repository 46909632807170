import React from 'react'
import { SvgIconProps } from '../ts/types/svgIcon.types'

export const ProfileIcon = ({
  fillColor,
  width,
  height,
  viewBox,
  newNotification = false
}: SvgIconProps) => (
  <svg
    style={{ fill: fillColor }}
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.9282 18.6305C20.954 17.3963 21.6675 15.9331 22.0082 14.3648C22.349 12.7965 22.307 11.1693 21.8859 9.62062C21.4647 8.07199 20.6767 6.64757 19.5886 5.46787C18.5006 4.28817 17.1443 3.38789 15.6347 2.84318C14.1251 2.29848 12.5065 2.12538 10.9159 2.33853C9.32519 2.55167 7.80926 3.1448 6.49631 4.06772C5.18335 4.99065 4.112 6.21622 3.37287 7.64076C2.63375 9.06531 2.2486 10.6469 2.25 12.2518C2.25054 14.5848 3.07269 16.8432 4.57219 18.6305L4.5579 18.6426C4.60791 18.7026 4.66505 18.7541 4.71648 18.8134C4.78077 18.8869 4.85005 18.9562 4.91648 19.0276C5.11649 19.2448 5.3222 19.4534 5.53792 19.6491C5.60364 19.7091 5.6715 19.7648 5.73793 19.8219C5.9665 20.0191 6.20151 20.2062 6.44508 20.3805C6.47651 20.402 6.50508 20.4298 6.53651 20.452V20.4434C8.20948 21.6207 10.2052 22.2525 12.2509 22.2525C14.2966 22.2525 16.2923 21.6207 17.9653 20.4434V20.452C17.9967 20.4298 18.0246 20.402 18.0567 20.3805C18.2996 20.2055 18.5353 20.0191 18.7639 19.8219C18.8303 19.7648 18.8982 19.7084 18.9639 19.6491C19.1796 19.4527 19.3853 19.2448 19.5853 19.0276C19.6518 18.9562 19.7203 18.8869 19.7853 18.8134C19.836 18.7541 19.8939 18.7026 19.9439 18.6419L19.9282 18.6305ZM12.2502 6.53741C12.8859 6.53741 13.5074 6.72592 14.036 7.07912C14.5646 7.43232 14.9766 7.93433 15.2199 8.52168C15.4631 9.10902 15.5268 9.75532 15.4028 10.3788C15.2787 11.0024 14.9726 11.5751 14.5231 12.0246C14.0735 12.4742 13.5008 12.7803 12.8773 12.9043C12.2538 13.0284 11.6075 12.9647 11.0201 12.7214C10.4328 12.4781 9.93076 12.0661 9.57756 11.5375C9.22436 11.009 9.03585 10.3875 9.03585 9.75175C9.03585 8.89926 9.3745 8.08167 9.97731 7.47887C10.5801 6.87606 11.3977 6.53741 12.2502 6.53741ZM6.5408 18.6305C6.55319 17.6926 6.93434 16.7973 7.60179 16.1383C8.26924 15.4792 9.16932 15.1095 10.1073 15.109H14.3931C15.3311 15.1095 16.2311 15.4792 16.8986 16.1383C17.566 16.7973 17.9472 17.6926 17.9596 18.6305C16.393 20.0422 14.359 20.8234 12.2502 20.8234C10.1414 20.8234 8.10737 20.0422 6.5408 18.6305Z"
      fillOpacity="0.5"
    />
  </svg>
)
