import { gql } from '@apollo/client'

const GET_TENANT_OPERATIONAL_HOURS = gql`
  query MyQuery($tenantId: String!) {
    getTenantOperationalHours(tenantId: $tenantId) {
      friday {
        closeTime
        isOpen
        openTime
      }
      monday {
        closeTime
        isOpen
        openTime
      }
      wednesday {
        openTime
        isOpen
        closeTime
      }
      tuesday {
        closeTime
        isOpen
        openTime
      }
      thursday {
        closeTime
        isOpen
        openTime
      }
      sunday {
        closeTime
        isOpen
        openTime
      }
      saturday {
        closeTime
        isOpen
        openTime
      }
    }
  }
`

export default GET_TENANT_OPERATIONAL_HOURS
