import { Drawer } from 'antd'
import { useContext } from 'react'
import classNames from 'classnames'
import { AppContext } from './Layout'
import { ChatMobileProps } from '../ts/types/all.types'

export const ChatMobile = ({ isChatOpened, setIsChatOpened }: ChatMobileProps) => {
  const { theme, tenant } = useContext(AppContext)
  return (
    <div className="shadow-contactCheffShadow">
      <Drawer
        open={!isChatOpened}
        onClose={() => setIsChatOpened(true)}
        title={'Contact Chef'}
        style={{ backgroundColor: 'white' }}
        placement="bottom"
        height={300}
        width={typeof window !== 'undefined' ? window?.innerWidth : 100}
      >
        <a
          className={classNames(
            `flex justify-center py-[13px] font-black text-lg font-[500] leading-22`
          )}
          style={{ color: theme?.customPrimary }}
          href={`tel:${tenant?.tenantAddresses?.mobileNumber}`}
        >
          {`Call chef on ${tenant?.tenantAddresses?.mobileNumber}`}
        </a>
        <div className="border-b text-[#2F5E0F1A]"></div>
        <a
          className={classNames(
            `flex w-[100%] justify-center py-[13px] font-black text-lg font-[500] leading-22`
          )}
          style={{ color: theme?.customPrimary }}
          href={`https://wa.me/${tenant?.tenantAddresses?.mobileNumber}`}
          target="_blank"
        >
          Chat with chef on WhatsApp
        </a>
        <div className="border-b text-[#2F5E0F1A]"></div>
      </Drawer>
      <style jsx global>{`
        .ant-drawer .ant-drawer-title {
          text-align: center;
          font-weight: bold;
          font-family: Avenir-black;
          font-size: 20px;
          line-height: 27px;
        }
      `}</style>
      <style jsx global>{`
        .ant-drawer .ant-drawer-header-title {
          flex-direction: row-reverse;
        }
      `}</style>
      <style jsx global>{`
        .anticon {
          color: ${theme?.customPrimary};
        }
      `}</style>
    </div>
  )
}
