import React from 'react'
import { SvgIconProps } from '../ts/types/svgIcon.types'

export const StarIcon = ({ fillColor, width, height, viewBox }: SvgIconProps) => {
  return (
    <svg
      fill="none"
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11_184)">
        <path
          d="M9.23437 11.25C9.15544 11.2503 9.07842 11.2257 9.01429 11.1797L5.99999 8.99438L2.98569 11.1797C2.9213 11.2264 2.84371 11.2514 2.76416 11.2511C2.6846 11.2508 2.6072 11.2253 2.54315 11.1781C2.47909 11.1309 2.43169 11.0646 2.40781 10.9887C2.38393 10.9128 2.38481 10.8313 2.4103 10.7559L3.58593 7.27383L0.539054 5.18438C0.473056 5.13917 0.423245 5.07404 0.396898 4.99851C0.370552 4.92298 0.36905 4.841 0.392613 4.76455C0.416175 4.6881 0.463567 4.62119 0.527865 4.5736C0.592162 4.526 0.669995 4.50022 0.749991 4.50001H4.5089L5.64327 1.00899C5.66771 0.933621 5.71539 0.867929 5.77948 0.821337C5.84356 0.774745 5.92076 0.749649 5.99999 0.749649C6.07922 0.749649 6.15642 0.774745 6.22051 0.821337C6.28459 0.867929 6.33227 0.933621 6.35671 1.00899L7.49109 4.50118H11.25C11.3301 4.50114 11.4081 4.52675 11.4726 4.57425C11.5371 4.62175 11.5847 4.68866 11.6084 4.76516C11.6321 4.84167 11.6307 4.92376 11.6044 4.99941C11.578 5.07505 11.5282 5.14029 11.4621 5.18555L8.41405 7.27383L9.58898 10.755C9.60801 10.8114 9.61337 10.8714 9.6046 10.9303C9.59583 10.9891 9.57319 11.045 9.53854 11.0934C9.5039 11.1418 9.45825 11.1812 9.40536 11.2084C9.35246 11.2356 9.29386 11.2499 9.23437 11.25Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_11_184">
          <path d="M0 6C0 2.68629 2.68629 0 6 0H12V12H6C2.68629 12 0 9.31371 0 6Z" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
