import _JSXStyle from 'styled-jsx/style'
import { Drawer } from 'antd'
import { useContext } from 'react'
import classNames from 'classnames'
import { AppContext } from './Layout'
import { NotDeliverableProps } from '../ts/types/all.types'

export const NotDeliverable = ({
  setOpen = () => {},
  order,
  setSelectedTab,
  address
}: NotDeliverableProps) => {
  const { mobileNotdeliverable, setMobileNotDeliverable, theme, setLocation, setOrderDetails } =
    useContext(AppContext)
  return (
    <div>
      <Drawer
        open={mobileNotdeliverable}
        onClose={() => setMobileNotDeliverable(false)}
        title="The kitchen does not deliver to your location!"
        style={{ backgroundColor: 'white', zIndex: 10 }}
        placement="bottom"
        height={326}
        width={typeof window !== 'undefined' ? window?.innerWidth : 100}
        closable={false}
      >
        <div className="px-[24px]">
          <div className="leading-25px mb-[8px] font-book text-md font-medium">
            Your location is away from the chef’s deliverable location.
          </div>
          <div className="leading-25px mb-[16px] font-book text-md font-medium">
            You can still order food and pickup by yourself from the chef’s place.
          </div>

          <button
            className={`font-balck mb-[16px] w-[100%] border-2 border-[${theme?.customPrimary}] py-[9px] text-sm font-[500] leading-16`}
            onClick={() => {
              order
                ? setMobileNotDeliverable(false)
                : (setOpen(true), setMobileNotDeliverable(false))
              setOrderDetails((prev: any) => ({ ...prev, selectedTab: 'Delivery' }))
            }}
            style={{ borderColor: theme?.customPrimary }}
          >
            Change location
          </button>
          <button
            className={classNames(
              `font-balck mb-[16px] w-[100%] bg-[${theme?.customPrimary}] py-[9px] text-sm font-[500] leading-16`
            )}
            onClick={() => {
              !order && setOpen(false)
              setMobileNotDeliverable(false)
              setLocation({
                main_text: address?.addressType || address?.main_text || '',
                secondary_text: address?.addressLine2 || address?.secondary_text || ''
              })
              order && setSelectedTab && setSelectedTab('Pickup from chef')
              setOrderDetails((prev: any) => ({ ...prev, selectedTab: 'Pickup' }))
            }}
            style={{ backgroundColor: theme?.customPrimary }}
          >
            Continue with pickup
          </button>
        </div>
      </Drawer>

      <style jsx global>{`
        .ant-drawer .ant-drawer-header {
          border-bottom: 0px;
          padding-left: 24px;
          padding-right: 24px;
          padding-top: 24px;
          padding-bottom: 16px;
        }
      `}</style>
      <style jsx global>{`
        .ant-drawer .ant-drawer-title {
          font-family: Avenir-black;
          font-weight: 800;
          font-size: 20px;
          line-height: 27px;
          color: ${theme?.customPrimary};
        }
      `}</style>

      <style jsx global>{`
        :where(.css-dev-only-do-not-override-sk7ap8)[class^='ant-drawer'] [class^='ant-drawer'],
        :where(.css-dev-only-do-not-override-sk7ap8)[class*=' ant-drawer'] [class^='ant-drawer'],
        :where(.css-dev-only-do-not-override-sk7ap8)[class^='ant-drawer'] [class*=' ant-drawer'],
        :where(.css-dev-only-do-not-override-sk7ap8)[class*=' ant-drawer'] [class*=' ant-drawer'] {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
      `}</style>
    </div>
  )
}
