import 'antd/dist/reset.css'
import '../styles/globals.css'
import type { AppProps } from 'next/app'
import { Layout } from '../components/Layout'
import { MediaContextProvider } from '../media'
import { ApolloProviderWrapper } from '../components/ApolloProviderWrapper'
import Head from 'next/head'
import { useRouter } from 'next/router'

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter()
  return (
    <>
      <Head>
        <title>{pageProps?.tenantData?.data?.getTenantDetails?.kitchenName}</title>
        <meta
          name="description"
          content={pageProps?.tenantData?.data?.getTenantDetails?.aboutTenant}
        />
      </Head>

      <MediaContextProvider>
        <ApolloProviderWrapper>
          <Head>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
            />
          </Head>
          {router?.pathname === '/termsAndConditions' || router?.pathname === '/privacyPolicy' ? (<Component {...pageProps} />) : 
          (<Layout
            themeSettings={pageProps?.themeSettings}
            tenantData={pageProps?.tenantData}
            dishTypesData={pageProps?.dishTypesData}
          >
            <Component {...pageProps} />
          </Layout>
          )}
        </ApolloProviderWrapper>
      </MediaContextProvider>
    </>
  )
}
