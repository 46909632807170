import React from 'react'
import { SvgIconProps } from '../ts/types/svgIcon.types'

export const CheckoutIcon = ({ fillColor, width, height, viewBox }: SvgIconProps) => {
  return (
    <svg
      fill="none"
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.3748 16.2917C25.3748 10.7031 21.1372 6.09213 15.7082 5.48796V3H13.2915V5.48796C7.86246 6.09213 3.62484 10.7031 3.62484 16.2917V18.7083H25.3748V16.2917ZM6.0415 16.2917C6.0415 11.6287 9.83688 7.83333 14.4998 7.83333C19.1628 7.83333 22.9582 11.6287 22.9582 16.2917H6.0415ZM2.4165 19.9167H26.5832V22.3333H2.4165V19.9167Z"
        fill={fillColor}
      />
    </svg>
  )
}
