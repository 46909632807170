import { createMedia } from '@artsy/fresnel'

const FeedzaaAppMedia = createMedia({
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1192
  }
})

export const mediaStyles = FeedzaaAppMedia.createMediaStyle()

export const { Media, MediaContextProvider } = FeedzaaAppMedia
