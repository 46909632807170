import React from 'react'
import { SvgIconProps } from '../ts/types/svgIcon.types'

export const PickUpIcon = ({ height, width, fillColor, viewBox, opacity }: SvgIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.2001 12.7824C19.925 12.7079 19.6317 12.7467 19.3853 12.8899L16.942 14.3003C16.9203 14.3996 16.8913 14.4973 16.8553 14.5923C16.7242 14.9563 16.4839 15.2709 16.1674 15.4932C15.8508 15.7155 15.4734 15.8348 15.0865 15.8347H11.8618C11.7286 15.8613 11.6068 15.9279 11.5124 16.0254C11.4067 16.1271 11.2391 16.1253 11.1357 16.0215C11.0322 15.9176 11.0311 15.7498 11.1332 15.6446C11.3278 15.4469 11.5857 15.324 11.8618 15.2972H15.0865C15.3643 15.297 15.6352 15.2108 15.8621 15.0504C16.0889 14.89 16.2606 14.6634 16.3536 14.4015C16.4359 14.1718 16.4745 13.9287 16.4672 13.6848H13.2053C12.7338 13.7073 12.2667 13.5863 11.8654 13.3379C11.2961 13.0023 10.6404 12.8425 9.98056 12.8786C8.59362 12.8786 7.35722 13.856 6.70208 14.5888L6.58247 14.433C6.49585 14.3202 6.36797 14.2463 6.22689 14.2276C6.08581 14.2089 5.94316 14.2469 5.83003 14.3333L4.54769 15.3139C4.43576 15.3997 4.36222 15.5261 4.3429 15.6659C4.32371 15.8055 4.3603 15.9472 4.44475 16.0601L3.9073 16.4729C3.84011 16.5233 3.80028 16.6022 3.7998 16.6863V20.4031C3.7998 20.4743 3.82812 20.5427 3.8785 20.5931C3.92889 20.6435 3.99727 20.6718 4.06853 20.6718H7.20759C7.26685 20.6719 7.32432 20.6524 7.37135 20.6165L7.72634 20.3443V20.3442C7.81368 20.4548 7.94109 20.5265 8.08097 20.544C8.22074 20.5615 8.36194 20.5235 8.47387 20.438L9.75382 19.4574C9.86671 19.3707 9.94061 19.2428 9.9592 19.1018C9.97792 18.9607 9.93989 18.818 9.85351 18.7049L9.79761 18.6319H9.79773C10.3169 18.2396 10.9429 18.0139 11.593 17.9845H14.0949C14.6158 17.9843 15.1273 17.8468 15.578 17.5857L20.4612 14.7531C20.7087 14.6107 20.8894 14.3755 20.9633 14.0997C21.0373 13.8239 20.9984 13.5301 20.8553 13.283C20.7123 13.0359 20.4768 12.8558 20.2008 12.7825L20.2001 12.7824ZM8.14725 20.0114L6.51114 17.8772L5.03313 15.9482L4.8749 15.7405L6.1546 14.7599L6.47707 15.1816L9.20551 18.7417L9.42613 19.0297L8.14725 20.0114Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9048 3.76748L16.6263 5.57712C16.6562 5.65225 16.6735 5.72032 16.6857 5.80081L17.6343 11.4179C17.6787 11.6813 17.4749 11.9212 17.2077 11.9198L15.196 11.9095C15.1667 11.9098 15.1414 11.892 15.1308 11.8632C15.1202 11.8345 15.1272 11.8034 15.1492 11.7829C15.2574 11.6819 15.326 11.5345 15.3275 11.3709L15.3774 5.82424L15.7733 3.78091C15.7794 3.74913 15.8036 3.72628 15.8343 3.72321C15.865 3.72001 15.893 3.73744 15.9048 3.76734L15.9048 3.76748ZM13.3914 4.62124H14.4006C14.5104 4.62124 14.6004 4.52694 14.6004 4.41179V4.21468C14.6004 4.09963 14.5104 4.00522 14.4006 4.00522H12.3821C12.2724 4.00522 12.1824 4.09964 12.1824 4.21468V4.41179C12.1824 4.52695 12.2724 4.62124 12.3821 4.62124H13.3914ZM13.3914 3.41162H15.3151C15.4039 3.41162 15.4941 3.48982 15.4764 3.58062L15.0377 5.82431L14.9877 11.371C14.9851 11.6746 14.7509 11.9225 14.4615 11.9225H11.0026C10.713 11.9225 10.4465 11.6731 10.4764 11.371L11.0169 5.9058L11.764 3.58078C11.7749 3.48847 11.8364 3.41178 11.9251 3.41178L13.3914 3.41162Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.8062 11.9419C9.80587 11.9421 9.8056 11.9421 9.80532 11.9421H9.80466C9.8039 11.9421 9.80335 11.942 9.80291 11.9417C9.80247 11.9412 9.80225 11.9407 9.80225 11.9399C9.80225 11.9392 9.80247 11.9386 9.80291 11.9382C9.80335 11.9377 9.8039 11.9375 9.80466 11.9375C9.80494 11.9375 9.80516 11.9376 9.80532 11.9377C9.8056 11.9377 9.80587 11.9378 9.8062 11.9379V11.9388C9.80587 11.9387 9.8056 11.9386 9.80532 11.9386C9.80516 11.9385 9.80494 11.9384 9.80466 11.9384C9.80433 11.9384 9.80406 11.9385 9.80379 11.9388C9.80362 11.9391 9.80357 11.9395 9.80357 11.9399C9.80357 11.9404 9.80362 11.9407 9.80379 11.941C9.80406 11.9413 9.80433 11.9415 9.80466 11.9415H9.80532C9.8056 11.9413 9.80587 11.9412 9.8062 11.941V11.9419Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.81009 11.9401H9.80982C9.80982 11.9399 9.80969 11.9398 9.80955 11.9398C9.80915 11.9398 9.80888 11.94 9.80874 11.9403C9.80854 11.9405 9.80848 11.9408 9.80848 11.9411V11.943H9.80713V11.939H9.80848V11.9398C9.80861 11.9395 9.80881 11.9392 9.80901 11.939C9.80915 11.9389 9.80942 11.9387 9.80982 11.9387H9.81009V11.9401Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.81485 11.9409V11.9414H9.81189C9.81189 11.9418 9.81196 11.9421 9.81216 11.9422H9.81297H9.81378C9.81411 11.9421 9.81445 11.9419 9.81485 11.9417V11.9428C9.81445 11.943 9.81411 11.943 9.81378 11.943H9.81297C9.81223 11.943 9.81162 11.9429 9.81109 11.9425C9.81068 11.9422 9.81055 11.9417 9.81055 11.9411C9.81055 11.9405 9.81068 11.9399 9.81109 11.9395C9.81162 11.939 9.81216 11.9387 9.8127 11.9387C9.81337 11.9387 9.81391 11.939 9.81432 11.9395C9.81465 11.9399 9.81485 11.9403 9.81485 11.9409H9.81485ZM9.81351 11.9406C9.81351 11.9403 9.81337 11.9401 9.81324 11.9401C9.81324 11.9399 9.81304 11.9398 9.8127 11.9398C9.8125 11.9398 9.8123 11.9399 9.81216 11.9401C9.81196 11.9401 9.81189 11.9403 9.81189 11.9406H9.81351Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.8171 11.9411C9.81689 11.9411 9.81669 11.9413 9.81656 11.9414C9.81635 11.9414 9.81629 11.9415 9.81629 11.9417V11.9422H9.81683C9.81716 11.9422 9.81743 11.9422 9.81763 11.942C9.81777 11.9418 9.8179 11.9416 9.8179 11.9414V11.9411H9.8171ZM9.81898 11.9406V11.943H9.8179V11.9425C9.8177 11.9427 9.81743 11.9429 9.8171 11.943H9.81629C9.81588 11.943 9.81555 11.943 9.81521 11.9428C9.81501 11.9426 9.81494 11.9422 9.81494 11.9417C9.81494 11.9413 9.81508 11.9411 9.81548 11.9409C9.81582 11.9407 9.81635 11.9406 9.8171 11.9406H9.8179V11.9403C9.8179 11.9402 9.81777 11.9401 9.81763 11.9401C9.81743 11.9399 9.81716 11.9398 9.81683 11.9398H9.81575C9.81555 11.9398 9.81548 11.9399 9.81548 11.9401V11.939H9.81602C9.81635 11.9389 9.81669 11.9387 9.8171 11.9387C9.81777 11.9387 9.81824 11.9389 9.81844 11.9393C9.81878 11.9397 9.81898 11.9401 9.81898 11.9406V11.9406Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.82109 11.9377V11.9386H9.82197V11.9393H9.82109V11.9408V11.9413H9.82131H9.82197V11.9419H9.82109C9.82066 11.9419 9.82033 11.9419 9.82022 11.9417C9.82005 11.9414 9.82 11.9412 9.82 11.9408V11.9393H9.81934V11.9386H9.82V11.9377H9.82109Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.82706 11.9409V11.9414H9.8241C9.8241 11.9418 9.82417 11.9421 9.82437 11.9422H9.82518H9.82598C9.82632 11.9421 9.82666 11.9419 9.82706 11.9417V11.9428C9.82666 11.943 9.82632 11.943 9.82598 11.943H9.82518C9.82444 11.943 9.82383 11.9429 9.82329 11.9425C9.82289 11.9422 9.82275 11.9417 9.82275 11.9411C9.82275 11.9405 9.82289 11.9399 9.82329 11.9395C9.82383 11.939 9.82437 11.9387 9.82491 11.9387C9.82558 11.9387 9.82612 11.939 9.82652 11.9395C9.82686 11.9399 9.82706 11.9403 9.82706 11.9409H9.82706ZM9.82572 11.9406C9.82572 11.9403 9.82558 11.9401 9.82545 11.9401C9.82545 11.9399 9.82524 11.9398 9.82491 11.9398C9.82471 11.9398 9.8245 11.9399 9.82437 11.9401C9.82417 11.9401 9.8241 11.9403 9.8241 11.9406H9.82572Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.82957 11.9393V11.9375H9.83067V11.9421H9.82957V11.9417C9.82957 11.9418 9.8294 11.942 9.82913 11.9421H9.82869C9.82825 11.9421 9.82786 11.942 9.82759 11.9417C9.82726 11.9414 9.82715 11.941 9.82715 11.9406C9.82715 11.94 9.82726 11.9396 9.82759 11.9393C9.82786 11.9388 9.82825 11.9386 9.82869 11.9386C9.8288 11.9386 9.82896 11.9387 9.82913 11.9388C9.8294 11.939 9.82957 11.9391 9.82957 11.9393V11.9393ZM9.82891 11.9415C9.82918 11.9415 9.82935 11.9414 9.82935 11.9412C9.82946 11.9411 9.82957 11.9409 9.82957 11.9406C9.82957 11.9403 9.82946 11.9401 9.82935 11.9399C9.82935 11.9396 9.82918 11.9395 9.82891 11.9395C9.82874 11.9395 9.82858 11.9396 9.82847 11.9399C9.8283 11.9401 9.82825 11.9403 9.82825 11.9406C9.82825 11.9409 9.8283 11.9411 9.82847 11.9412C9.82858 11.9414 9.82874 11.9415 9.82891 11.9415Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.83623 11.9415C9.83651 11.9415 9.83667 11.9414 9.83667 11.9412C9.83678 11.9411 9.83689 11.9409 9.83689 11.9406C9.83689 11.9403 9.83678 11.9401 9.83667 11.9399C9.83667 11.9396 9.83651 11.9395 9.83623 11.9395C9.83607 11.9395 9.8359 11.9396 9.83579 11.9399C9.83563 11.9401 9.83557 11.9403 9.83557 11.9406C9.83557 11.9407 9.83563 11.941 9.83579 11.9412C9.8359 11.9414 9.83607 11.9415 9.83623 11.9415ZM9.83557 11.9393L9.83601 11.9388C9.83629 11.9387 9.83651 11.9386 9.83667 11.9386C9.83711 11.9386 9.83744 11.9388 9.83777 11.9393C9.83805 11.9396 9.83821 11.94 9.83821 11.9406C9.83821 11.941 9.83805 11.9414 9.83777 11.9417C9.83744 11.942 9.83711 11.9421 9.83667 11.9421H9.83601C9.83585 11.942 9.83568 11.9418 9.83557 11.9417V11.9421H9.83447V11.9375H9.83557V11.9393Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.83838 11.939H9.83948L9.84036 11.9412L9.84102 11.939H9.84234L9.8408 11.9425C9.84063 11.9429 9.84047 11.9432 9.84036 11.9434C9.84019 11.9435 9.83997 11.9436 9.8397 11.9436H9.83904V11.9429H9.83926C9.83953 11.9429 9.8397 11.9428 9.8397 11.9427C9.8397 11.9427 9.83975 11.9426 9.83992 11.9425V11.9423L9.83838 11.939Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.84631 11.9418V11.9436H9.84521V11.9389H9.84631V11.9394L9.84675 11.9389C9.84703 11.9388 9.84725 11.9387 9.84741 11.9387C9.84785 11.9387 9.84818 11.9389 9.84851 11.9394C9.84879 11.9397 9.84895 11.9401 9.84895 11.9407C9.84895 11.9411 9.84879 11.9415 9.84851 11.9418C9.84818 11.9421 9.84785 11.9422 9.84741 11.9422H9.84675C9.84659 11.9421 9.84642 11.942 9.84631 11.9418V11.9418ZM9.84697 11.9396C9.84681 11.9396 9.84664 11.9398 9.84653 11.94C9.84637 11.9402 9.84631 11.9404 9.84631 11.9407C9.84631 11.9409 9.84637 11.9411 9.84653 11.9414C9.84664 11.9415 9.84681 11.9416 9.84697 11.9416C9.84725 11.9416 9.84741 11.9415 9.84741 11.9414C9.84752 11.9412 9.84763 11.941 9.84763 11.9407C9.84763 11.9404 9.84752 11.9402 9.84741 11.94C9.84741 11.9398 9.84725 11.9396 9.84697 11.9396Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.85274 11.9398C9.85254 11.9398 9.85234 11.9399 9.8522 11.9401C9.852 11.9403 9.85193 11.9406 9.85193 11.9411C9.85193 11.9415 9.852 11.9418 9.8522 11.942C9.85234 11.9422 9.85254 11.9422 9.85274 11.9422C9.85308 11.9422 9.85335 11.9422 9.85355 11.942C9.85368 11.9418 9.85382 11.9415 9.85382 11.9411C9.85382 11.9406 9.85368 11.9403 9.85355 11.9401C9.85335 11.9399 9.85308 11.9398 9.85274 11.9398ZM9.85274 11.9387C9.85341 11.9387 9.85395 11.939 9.85436 11.9395C9.85489 11.9399 9.85516 11.9405 9.85516 11.9411C9.85516 11.9417 9.85489 11.9422 9.85436 11.9425C9.85395 11.9429 9.85341 11.943 9.85274 11.943C9.8522 11.943 9.85166 11.9429 9.85112 11.9425C9.85072 11.9422 9.85059 11.9417 9.85059 11.9411C9.85059 11.9405 9.85072 11.9399 9.85112 11.9395C9.85166 11.939 9.8522 11.9387 9.85274 11.9387Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.85608 11.9418V11.9436H9.85498V11.9389H9.85608V11.9394L9.85652 11.9389C9.85679 11.9388 9.85701 11.9387 9.85718 11.9387C9.85762 11.9387 9.85795 11.9389 9.85828 11.9394C9.85855 11.9397 9.85872 11.9401 9.85872 11.9407C9.85872 11.9411 9.85855 11.9415 9.85828 11.9418C9.85795 11.9421 9.85762 11.9422 9.85718 11.9422H9.85652C9.85635 11.9421 9.85619 11.942 9.85608 11.9418V11.9418ZM9.85674 11.9396C9.85657 11.9396 9.85641 11.9398 9.8563 11.94C9.85613 11.9402 9.85608 11.9404 9.85608 11.9407C9.85608 11.9409 9.85613 11.9411 9.8563 11.9414C9.85641 11.9415 9.85657 11.9416 9.85674 11.9416C9.85701 11.9416 9.85718 11.9415 9.85718 11.9414C9.85729 11.9412 9.8574 11.941 9.8574 11.9407C9.8574 11.9404 9.85729 11.9402 9.85718 11.94C9.85718 11.9398 9.85701 11.9396 9.85674 11.9396Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.86314 11.939V11.9401H9.86234C9.86234 11.9399 9.86213 11.9398 9.8618 11.9398C9.8616 11.9398 9.86133 11.94 9.86099 11.9403C9.86079 11.9405 9.86072 11.9408 9.86072 11.9411C9.86072 11.9413 9.86079 11.9416 9.86099 11.942C9.86133 11.9422 9.8616 11.9422 9.8618 11.9422H9.86261C9.86274 11.9421 9.86294 11.942 9.86314 11.942V11.943H9.86234H9.8618C9.86106 11.943 9.86045 11.9429 9.85991 11.9425C9.85951 11.9422 9.85938 11.9417 9.85938 11.9411C9.85938 11.9405 9.85951 11.9399 9.85991 11.9395C9.86045 11.939 9.86106 11.9387 9.8618 11.9387C9.86193 11.9387 9.86213 11.9389 9.86234 11.939H9.86314Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.86592 11.9398C9.86572 11.9398 9.86552 11.9399 9.86538 11.9401C9.86518 11.9403 9.86512 11.9406 9.86512 11.9411C9.86512 11.9415 9.86518 11.9418 9.86538 11.942C9.86552 11.9422 9.86572 11.9422 9.86592 11.9422C9.86626 11.9422 9.86653 11.9422 9.86673 11.942C9.86687 11.9418 9.867 11.9415 9.867 11.9411C9.867 11.9406 9.86687 11.9403 9.86673 11.9401C9.86653 11.9399 9.86626 11.9398 9.86592 11.9398ZM9.86592 11.9387C9.8666 11.9387 9.86713 11.939 9.86754 11.9395C9.86808 11.9399 9.86835 11.9405 9.86835 11.9411C9.86835 11.9417 9.86808 11.9422 9.86754 11.9425C9.86713 11.9429 9.8666 11.943 9.86592 11.943C9.86538 11.943 9.86485 11.9429 9.86431 11.9425C9.8639 11.9422 9.86377 11.9417 9.86377 11.9411C9.86377 11.9405 9.8639 11.9399 9.86431 11.9395C9.86485 11.939 9.86538 11.9387 9.86592 11.9387Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.87113 11.9401H9.87086C9.87086 11.9399 9.87072 11.9398 9.87059 11.9398C9.87018 11.9398 9.86991 11.94 9.86978 11.9403C9.86958 11.9405 9.86951 11.9408 9.86951 11.9411V11.943H9.86816V11.939H9.86951V11.9398C9.86964 11.9395 9.86985 11.9392 9.87005 11.939C9.87018 11.9389 9.87045 11.9387 9.87086 11.9387H9.87113V11.9401Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.87589 11.9406V11.943H9.87427V11.9411V11.9406V11.9401H9.87401C9.87401 11.9399 9.87387 11.9398 9.87374 11.9398C9.87353 11.9398 9.87333 11.94 9.8732 11.9403C9.873 11.9405 9.87293 11.9408 9.87293 11.9411V11.943H9.87158V11.939H9.87293V11.9395L9.87347 11.939C9.8738 11.9389 9.87407 11.9387 9.87427 11.9387C9.87481 11.9387 9.87515 11.9389 9.87535 11.9393C9.87569 11.9397 9.87589 11.9401 9.87589 11.9406V11.9406Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.87813 11.9411C9.87793 11.9411 9.87773 11.9413 9.87759 11.9414C9.87739 11.9414 9.87732 11.9415 9.87732 11.9417V11.9422H9.87786C9.8782 11.9422 9.87847 11.9422 9.87867 11.942C9.8788 11.9418 9.87894 11.9416 9.87894 11.9414V11.9411H9.87813ZM9.88001 11.9406V11.943H9.87894V11.9425C9.87874 11.9427 9.87847 11.9429 9.87813 11.943H9.87732C9.87692 11.943 9.87658 11.943 9.87625 11.9428C9.87604 11.9426 9.87598 11.9422 9.87598 11.9417C9.87598 11.9413 9.87611 11.9411 9.87652 11.9409C9.87685 11.9407 9.87739 11.9406 9.87813 11.9406H9.87894V11.9403C9.87894 11.9402 9.8788 11.9401 9.87867 11.9401C9.87847 11.9399 9.8782 11.9398 9.87786 11.9398H9.87678C9.87658 11.9398 9.87652 11.9399 9.87652 11.9401V11.939H9.87705C9.87739 11.9389 9.87773 11.9387 9.87813 11.9387C9.8788 11.9387 9.87927 11.9389 9.87948 11.9393C9.87981 11.9397 9.88001 11.9401 9.88001 11.9406V11.9406Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.88382 11.9401H9.88355C9.88355 11.9399 9.88342 11.9398 9.88328 11.9398C9.88288 11.9398 9.88261 11.94 9.88247 11.9403C9.88227 11.9405 9.88221 11.9408 9.88221 11.9411V11.943H9.88086V11.939H9.88221V11.9398C9.88234 11.9395 9.88254 11.9392 9.88274 11.939C9.88288 11.9389 9.88315 11.9387 9.88355 11.9387H9.88382V11.9401Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.88506 11.9377V11.9386H9.88594V11.9393H9.88506V11.9408V11.9413H9.88528H9.88594V11.9419H9.88506C9.88462 11.9419 9.88429 11.9419 9.88418 11.9417C9.88402 11.9414 9.88396 11.9412 9.88396 11.9408V11.9393H9.8833V11.9386H9.88396V11.9377H9.88506Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.89022 11.939V11.9401C9.88981 11.9399 9.88948 11.9398 9.88914 11.9398H9.8886H9.88806V11.9401V11.9406H9.8886H9.88887C9.88941 11.9406 9.88975 11.9407 9.88995 11.9409C9.89029 11.9411 9.89049 11.9414 9.89049 11.942C9.89049 11.9424 9.89029 11.9426 9.88995 11.9428C9.88975 11.943 9.88928 11.943 9.8886 11.943H9.88753H9.88699V11.942C9.88712 11.9422 9.88732 11.9422 9.88753 11.9422H9.8886H9.88887C9.88901 11.9422 9.88914 11.9422 9.88914 11.942C9.88914 11.9418 9.88901 11.9417 9.88887 11.9417C9.88887 11.9415 9.88874 11.9414 9.8886 11.9414H9.88833C9.8878 11.9414 9.88732 11.9413 9.88699 11.9411C9.88679 11.9408 9.88672 11.9405 9.88672 11.9401C9.88672 11.9397 9.88685 11.9395 9.88726 11.9393C9.88759 11.9389 9.88806 11.9387 9.8886 11.9387C9.88874 11.9387 9.88894 11.9389 9.88914 11.939H9.89022Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.80488 11.9441V11.9448H9.80422C9.80422 11.9448 9.80411 11.9449 9.804 11.945V11.9452V11.9454H9.80488V11.9461H9.804V11.9487H9.80291V11.9461H9.80225V11.9454H9.80291V11.9452C9.80291 11.9448 9.80296 11.9445 9.80313 11.9443C9.8034 11.9442 9.80379 11.9441 9.80422 11.9441H9.80488Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.80814 11.9467H9.80787C9.80787 11.9465 9.80773 11.9464 9.8076 11.9464C9.80719 11.9464 9.80693 11.9466 9.80679 11.9469C9.80659 11.9471 9.80652 11.9474 9.80652 11.9477V11.9496H9.80518V11.9456H9.80652V11.9464C9.80666 11.9461 9.80686 11.9458 9.80706 11.9456C9.80719 11.9454 9.80746 11.9453 9.80787 11.9453H9.80814V11.9467Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.81075 11.9464C9.81055 11.9464 9.81034 11.9465 9.81021 11.9467C9.81001 11.9469 9.80994 11.9472 9.80994 11.9477C9.80994 11.9481 9.81001 11.9484 9.81021 11.9485C9.81034 11.9487 9.81055 11.9488 9.81075 11.9488C9.81108 11.9488 9.81135 11.9487 9.81156 11.9485C9.81169 11.9484 9.81182 11.9481 9.81182 11.9477C9.81182 11.9472 9.81169 11.9469 9.81156 11.9467C9.81135 11.9465 9.81108 11.9464 9.81075 11.9464ZM9.81075 11.9453C9.81142 11.9453 9.81196 11.9456 9.81236 11.9461C9.8129 11.9465 9.81317 11.9471 9.81317 11.9477C9.81317 11.9483 9.8129 11.9487 9.81236 11.9491C9.81196 11.9495 9.81142 11.9496 9.81075 11.9496C9.81021 11.9496 9.80967 11.9495 9.80913 11.9491C9.80873 11.9487 9.80859 11.9483 9.80859 11.9477C9.80859 11.9471 9.80873 11.9465 9.80913 11.9461C9.80967 11.9456 9.81021 11.9453 9.81075 11.9453Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.81607 11.9462C9.81618 11.9459 9.81634 11.9457 9.81651 11.9455C9.81678 11.9454 9.817 11.9453 9.81717 11.9453C9.81761 11.9453 9.81788 11.9455 9.81805 11.9458C9.81832 11.9461 9.81848 11.9464 9.81848 11.9469V11.9488H9.81739V11.9471V11.9469C9.81739 11.9467 9.81728 11.9466 9.81717 11.9464C9.81717 11.9463 9.81706 11.9462 9.81695 11.9462C9.81678 11.9462 9.81662 11.9464 9.81651 11.9466C9.81634 11.9468 9.81629 11.947 9.81629 11.9473V11.9488H9.81519V11.9471C9.81519 11.9468 9.81508 11.9466 9.81497 11.9464C9.81497 11.9463 9.81486 11.9462 9.81475 11.9462C9.81458 11.9462 9.81442 11.9464 9.81431 11.9466C9.81414 11.9468 9.81409 11.947 9.81409 11.9473V11.9488H9.81299V11.9455H9.81409V11.946L9.81453 11.9455C9.81464 11.9454 9.81486 11.9453 9.81519 11.9453C9.8153 11.9453 9.81546 11.9454 9.81563 11.9455C9.8159 11.9457 9.81607 11.9459 9.81607 11.9462Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.82354 11.9446V11.9455H9.82442V11.9461H9.82354V11.9477V11.9481H9.82376H9.82442V11.9488H9.82354C9.8231 11.9488 9.82277 11.9487 9.82266 11.9485C9.82249 11.9483 9.82244 11.948 9.82244 11.9477V11.9461H9.82178V11.9455H9.82244V11.9446H9.82354Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.8292 11.9467V11.9487H9.82788V11.9472V11.9467V11.9463H9.82766C9.82766 11.9462 9.82755 11.9461 9.82744 11.9461C9.82728 11.9461 9.82711 11.9462 9.827 11.9465C9.82684 11.9467 9.82678 11.9469 9.82678 11.9472V11.9487H9.82568V11.9441H9.82678V11.9459L9.82722 11.9454C9.8275 11.9453 9.82772 11.9452 9.82788 11.9452C9.82832 11.9452 9.8286 11.9454 9.82876 11.9456C9.82904 11.946 9.8292 11.9463 9.8292 11.9467Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.83439 11.9475V11.948H9.83142C9.83142 11.9484 9.83149 11.9487 9.83169 11.9488H9.8325H9.83331C9.83365 11.9487 9.83398 11.9485 9.83439 11.9483V11.9494C9.83398 11.9496 9.83365 11.9496 9.83331 11.9496H9.8325C9.83176 11.9496 9.83116 11.9495 9.83062 11.9491C9.83021 11.9487 9.83008 11.9483 9.83008 11.9477C9.83008 11.9471 9.83021 11.9465 9.83062 11.9461C9.83116 11.9456 9.83169 11.9453 9.83223 11.9453C9.8329 11.9453 9.83344 11.9456 9.83385 11.9461C9.83418 11.9465 9.83439 11.9469 9.83439 11.9475H9.83439ZM9.83304 11.9472C9.83304 11.9469 9.83291 11.9467 9.83277 11.9467C9.83277 11.9465 9.83257 11.9464 9.83223 11.9464C9.83203 11.9464 9.83183 11.9465 9.83169 11.9467C9.83149 11.9467 9.83142 11.9469 9.83142 11.9472H9.83304Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.83691 11.9443H9.83801L9.83977 11.9474V11.9443H9.84087V11.9487H9.83955L9.83779 11.9457V11.9487H9.83691V11.9443Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.84493 11.9464C9.84473 11.9464 9.84452 11.9465 9.84439 11.9467C9.84419 11.9469 9.84412 11.9472 9.84412 11.9477C9.84412 11.9481 9.84419 11.9484 9.84439 11.9485C9.84452 11.9487 9.84473 11.9488 9.84493 11.9488C9.84526 11.9488 9.84553 11.9487 9.84573 11.9485C9.84587 11.9484 9.846 11.9481 9.846 11.9477C9.846 11.9472 9.84587 11.9469 9.84573 11.9467C9.84553 11.9465 9.84526 11.9464 9.84493 11.9464ZM9.84493 11.9453C9.8456 11.9453 9.84614 11.9456 9.84654 11.9461C9.84708 11.9465 9.84735 11.9471 9.84735 11.9477C9.84735 11.9483 9.84708 11.9487 9.84654 11.9491C9.84614 11.9495 9.8456 11.9496 9.84493 11.9496C9.84439 11.9496 9.84385 11.9495 9.84331 11.9491C9.84291 11.9487 9.84277 11.9483 9.84277 11.9477C9.84277 11.9471 9.84291 11.9465 9.84331 11.9461C9.84385 11.9456 9.84439 11.9453 9.84493 11.9453Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.84717 11.9482V11.9458H9.84851V11.9461V11.9471V11.9477V11.9485V11.9488C9.84865 11.9488 9.84878 11.9489 9.84878 11.949H9.84905C9.84939 11.949 9.84959 11.949 9.84959 11.9488C9.84973 11.9484 9.84986 11.9481 9.84986 11.9477V11.9458H9.85121V11.9498H9.84986V11.9493C9.84966 11.9495 9.84946 11.9497 9.84932 11.9498H9.84851C9.84811 11.9498 9.84777 11.9498 9.84744 11.9496C9.84724 11.9492 9.84717 11.9488 9.84717 11.9482V11.9482Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.85636 11.9472V11.9496H9.85474V11.9477V11.9472V11.9467H9.85447C9.85447 11.9465 9.85434 11.9464 9.8542 11.9464C9.854 11.9464 9.8538 11.9466 9.85367 11.9469C9.85346 11.9471 9.8534 11.9474 9.8534 11.9477V11.9496H9.85205V11.9456H9.8534V11.9461L9.85394 11.9456C9.85427 11.9454 9.85454 11.9453 9.85474 11.9453C9.85528 11.9453 9.85562 11.9455 9.85582 11.9459C9.85616 11.9463 9.85636 11.9467 9.85636 11.9472V11.9472Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.85938 11.9443H9.86113C9.86168 11.9443 9.86212 11.9445 9.86245 11.9448C9.86273 11.9449 9.86289 11.9453 9.86289 11.9459C9.86289 11.9463 9.86273 11.9467 9.86245 11.947C9.86212 11.9471 9.86168 11.9472 9.86113 11.9472H9.86047V11.9487H9.85938V11.9443ZM9.86047 11.9452V11.9463H9.86113H9.86157C9.86168 11.9462 9.86179 11.946 9.86179 11.9459C9.86179 11.9456 9.86168 11.9454 9.86157 11.9454C9.86141 11.9453 9.86124 11.9452 9.86113 11.9452H9.86047Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.86673 11.9467H9.86646C9.86646 11.9465 9.86633 11.9464 9.86619 11.9464C9.86579 11.9464 9.86552 11.9466 9.86538 11.9469C9.86518 11.9471 9.86512 11.9474 9.86512 11.9477V11.9496H9.86377V11.9456H9.86512V11.9464C9.86525 11.9461 9.86545 11.9458 9.86565 11.9456C9.86579 11.9454 9.86606 11.9453 9.86646 11.9453H9.86673V11.9467Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.86885 11.9464C9.86865 11.9464 9.86845 11.9465 9.86831 11.9467C9.86811 11.9469 9.86805 11.9472 9.86805 11.9477C9.86805 11.9481 9.86811 11.9484 9.86831 11.9485C9.86845 11.9487 9.86865 11.9488 9.86885 11.9488C9.86919 11.9488 9.86946 11.9487 9.86966 11.9485C9.8698 11.9484 9.86993 11.9481 9.86993 11.9477C9.86993 11.9472 9.8698 11.9469 9.86966 11.9467C9.86946 11.9465 9.86919 11.9464 9.86885 11.9464ZM9.86885 11.9453C9.86953 11.9453 9.87006 11.9456 9.87047 11.9461C9.87101 11.9465 9.87128 11.9471 9.87128 11.9477C9.87128 11.9483 9.87101 11.9487 9.87047 11.9491C9.87006 11.9495 9.86953 11.9496 9.86885 11.9496C9.86831 11.9496 9.86778 11.9495 9.86724 11.9491C9.86683 11.9487 9.8667 11.9483 9.8667 11.9477C9.8667 11.9471 9.86683 11.9465 9.86724 11.9461C9.86778 11.9456 9.86831 11.9453 9.86885 11.9453Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.87127 11.9454H9.87236V11.9487C9.87236 11.9491 9.8722 11.9494 9.87192 11.9496C9.87176 11.9499 9.87148 11.95 9.87105 11.95H9.87061V11.9494H9.87083C9.87094 11.9494 9.87105 11.9493 9.87105 11.9491C9.87116 11.9491 9.87127 11.949 9.87127 11.9487V11.9454ZM9.87127 11.9441H9.87236V11.945H9.87127V11.9441Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.87833 11.9475V11.948H9.87537C9.87537 11.9484 9.87544 11.9487 9.87564 11.9488H9.87645H9.87725C9.87759 11.9487 9.87793 11.9485 9.87833 11.9483V11.9494C9.87793 11.9496 9.87759 11.9496 9.87725 11.9496H9.87645C9.87571 11.9496 9.8751 11.9495 9.87456 11.9491C9.87416 11.9487 9.87402 11.9483 9.87402 11.9477C9.87402 11.9471 9.87416 11.9465 9.87456 11.9461C9.8751 11.9456 9.87564 11.9453 9.87618 11.9453C9.87685 11.9453 9.87739 11.9456 9.87779 11.9461C9.87813 11.9465 9.87833 11.9469 9.87833 11.9475H9.87833ZM9.87699 11.9472C9.87699 11.9469 9.87685 11.9467 9.87672 11.9467C9.87672 11.9465 9.87651 11.9464 9.87618 11.9464C9.87598 11.9464 9.87577 11.9465 9.87564 11.9467C9.87544 11.9467 9.87537 11.9469 9.87537 11.9472H9.87699Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.88219 11.9456V11.9467H9.88138C9.88138 11.9465 9.88118 11.9464 9.88084 11.9464C9.88064 11.9464 9.88037 11.9466 9.88003 11.9469C9.87983 11.9471 9.87976 11.9474 9.87976 11.9477C9.87976 11.9479 9.87983 11.9482 9.88003 11.9485C9.88037 11.9487 9.88064 11.9488 9.88084 11.9488H9.88165C9.88178 11.9487 9.88199 11.9485 9.88219 11.9485V11.9496H9.88138H9.88084C9.8801 11.9496 9.87949 11.9495 9.87896 11.9491C9.87855 11.9487 9.87842 11.9483 9.87842 11.9477C9.87842 11.9471 9.87855 11.9465 9.87896 11.9461C9.87949 11.9456 9.8801 11.9453 9.88084 11.9453C9.88098 11.9453 9.88118 11.9454 9.88138 11.9456H9.88219Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
      <path
        d="M9.88408 11.9446V11.9455H9.88496V11.9461H9.88408V11.9477V11.9481H9.8843H9.88496V11.9488H9.88408C9.88364 11.9488 9.88331 11.9487 9.8832 11.9485C9.88304 11.9483 9.88298 11.948 9.88298 11.9477V11.9461H9.88232V11.9455H9.88298V11.9446H9.88408Z"
        fill={fillColor}
        fillOpacity={opacity ? '0.5' : '1'}
      />
    </svg>
  )
}
